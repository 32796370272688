import React, {Component} from 'react';
import CustomerServiceMap from '../CustomerServiceMap';
import CustomerServiceContainer from '../CustomerServiceContainer';
import Select from 'react-select';
import {Trans} from 'gatsby-plugin-react-i18next';

export default class CustomerService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: [],
      activeAddress: {},
    };
  }

  initAddresses() {
    this.setState({addresses: [
        {
          id: 1,
          city: 'Rīga',
          street: 'Avotu iela 60, LV-1009',
          position: {
            lat: 56.952495,
            lng: 24.141414,
          },
          email: 'ofiss@evors.lv',
          administration: '+371 67277779',
          technical: '+371 29297101, +371 29298209',
          physical: '+371 27885887',
          accountant: '+371 67842130',
          fax: '+371 67842132',
        },
        {
          id: 2,
          city: 'Ogre',
          street: 'Mālkalnes pr. 26, Ogre, LV-5005',
          position: {
            lat: 56.823510,
            lng: 24.598613,
          },
          email: 'ofiss@evors.lv',
          administration: '+371 65067294',
          manager: '+371 26459060',
        },
        {
          id: 3,
          city: 'Ikšķile',
          street: 'Skolas iela 2, Ikšķile, LV-5052',
          position: {
            lat: 56.836022,
            lng: 24.494006,
          },
          email: 'ofiss@evors.lv',
          administration: '+371 65036297',
          manager: '+371 26459060',
        },
        {
          id: 4,
          city: 'Tukums',
          street: 'Raudas iela 8, Tukums, LV-3101',
          position: {
            lat: 56.969176,
            lng: 23.156335,
          },
          email: 'ofiss@evors.lv',
          administration: '+371 63124363',
          manager: '+371 29241264',
        },
        {
          id: 5,
          city: 'Bauska',
          street: 'Salātu iela 5 Bauska, Bauskas pilsēta, LV-3901',
          position: {
            lat: 56.410634,
            lng: 24.196389,
          },
          email: 'ofiss@evors.lv',
          administration: '+371 29404100',
          manager: '+371 29404100',
        },
        {
          id: 6,
          city: 'Madona',
          position: {
            lat: 56.855970,
            lng: 26.223819,
          },
          email: 'ofiss@evors.lv',
          administration: '+371 29298209, +371 29297101',
        },
      ]})
  }

  getDropdownOptions() {
    return this.state.addresses.map(
      option => ({value: option.id, label: option.city}));
  }

  handleChange(option) {
    /**
     * Show certain option, otherwise - show all results.
     */
    this.setState({
      activeAddress: option && option.value ? this.state.addresses.filter(
        address => option.value === address.id) : {}
    });
  }

  getDropdownValue() {
    const address = this.state.activeAddress

    return address ? {
      label: address.city,
      value: address.id,
    } : null
  }

  componentDidMount() {
    this.initAddresses();
  }

  render() {
    return (this.state.addresses.length) ? (
      <div className={'container contacts'}>
        <h2 className={'contacts__title'}>
          <Trans>customer.service.title</Trans>
        </h2>
        <div className={'row'}>
          <div className={'col-lg-5'}>
            <div className={'select-dropdown__wrapper'}>
              <span className={'select-dropdown__label'}>
                <Trans>customer.service.select.address</Trans>
              </span>
              <Select className={'select-dropdown'}
                      classNamePrefix="select-dropdown"
                      placeholder={<Trans>dropdown.placeholder</Trans>}
                      isSearchable={false}
                      isClearable={true}
                      options={this.getDropdownOptions()}
                      onChange={this.handleChange.bind(this)}/>

              <CustomerServiceContainer locations={this.state.activeAddress}/>
            </div>
          </div>
          <div className={'col-lg-7'}>
            <CustomerServiceMap locations={this.state.activeAddress.length > 0 ? this.state.activeAddress : this.state.addresses}/>
          </div>
        </div>
      </div>
    ) : null;
  }
}
