import React, {useState, useEffect} from 'react';
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import CustomerServiceMapMarker from "../CustomerServiceMapMarker";

const containerStyle = {
  width: '100%',
  height: '343px'
};

/**
 * https://maps.googleapis.com/maps/api/js?key=AIzaSyDHLfY7t03d3YsosE2YS7MnrBqY6rmjxGw&sensor=false&language=lv&libraries=places
 *
 * @returns {JSX.Element}
 * @constructor
 */
function ContactMap({locations}) {
  const [map, setMap] = useState(null)

  const onLoad = React.useCallback(function callback(map) {
    if (locations) {
      const bounds = new window.google.maps.LatLngBounds();

      map.fitBounds(bounds);
      setMap(map)
    }
  }, [locations])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const centerMap = (locations) => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      locations && locations.forEach((address) => {
        bounds.extend(address.position)
      })

      map.fitBounds(bounds);
    }
  }

  useEffect(() => {
    centerMap(locations);
  });

  return locations.length ? (
    <LoadScript
      googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY || null}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        defaultZoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          minZoom: 5,
          maxZoom: 15
        }}
      >
        { /* Child components, such as markers, info windows, etc. */}
        {locations.map((address, index) => <CustomerServiceMapMarker address={address} key={index}/>)}
      </GoogleMap>
    </LoadScript>
  ) : null;
}

export default React.memo(ContactMap)
