import React, {Component} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';

export default class CustomerServiceContainer extends Component {
  render() {
    const { locations } = this.props;

    return locations.length ? locations.map((address, index) => {
      return (
        <div className={"contacts-container"} key={index}>
          <h3>{address.city}</h3>
          {address.street && <p><Trans>service.address</Trans> <strong>{address.street}</strong></p>}
          {address.email && <p><Trans>service.email</Trans> <strong>{address.email}</strong></p>}
          {address.administration && <p><Trans>service.administration</Trans> <strong>{address.administration}</strong></p>}
          {address.technical && <p><Trans>service.technical</Trans> <strong>{address.technical}</strong></p>}
          {address.physical && <p><Trans>service.physical</Trans> <strong>{address.physical}</strong></p>}
          {address.accountant && <p><Trans>service.accountant</Trans> <strong>{address.accountant}</strong></p>}
          {address.manager && <p><Trans>service.manager</Trans> <strong>{address.manager}</strong></p>}
          {address.fax && <p><Trans>service.fax</Trans> <strong>{address.fax}</strong></p>}
        </div>
      )
    }) : null;
  }
}
