import {InfoWindow, Marker} from "@react-google-maps/api";
import React, {useState} from "react";

/**
 *
 * @param address
 * @param index
 * @returns {JSX.Element}
 */
export default function CustomerServiceMapMarker({address}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Marker
        position={address.position}
        onClick={() => setIsOpen(true)}
      >
        {
          isOpen &&
          <InfoWindow onCloseClick={() => setIsOpen(false)}>
            <div className="info-window">
              <h4>{address.city}</h4>
              <p>{address.street}</p>
              <p>{address.email}</p>
              <p>{address.phone}</p>
            </div>
          </InfoWindow>
        }
      </Marker>
    </>
  )
}
