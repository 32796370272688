import React from 'react';
import Layout from '../components/layout';
import HomepageSlider from '../components/HomepageSlider';
import HomeContent from '../components/HomeContent';
import CustomerService from '../components/CustomerService';
import Solutions from '../components/Solutions';
import HomeNews from '../components/HomeNews';
import SEO from "../components/SEO";
import {graphql} from 'gatsby';

/**
 * Map the homepage content
 *
 * @param data
 * @param languageSlug
 */
function getHomepageContent(data, languageSlug) {
  const {language, translations} = data;
  const translation = translations.filter(translation => translation.language.slug === languageSlug).shift();

  return (language.slug === languageSlug) ? data : translation;
}

export default function Home({data, pageContext}) {
  const {allWpPost, allWpHomepageSlide, wp} = data;
  const {language} = pageContext;
  const content = getHomepageContent(wp.homepage, language);
  const seo = content.seo;

  return (
    <>
      <SEO seoData={seo}/>
      <Layout language={pageContext.language}>
        <HomepageSlider slides={allWpHomepageSlide}/>
        <HomeContent data={content}/>
        <HomeNews news={allWpPost}/>
        <div className={'wrapper-gray--dark'}>
          <CustomerService/>
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
    fragment Thumbnail on File {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }
    query HomeContent($homePostsLimit: Int!, $language: String!) {
        allWpPost(
            limit: $homePostsLimit
            filter: { nodeType: { in: ["Post", "Alot"] }, language: { slug: { eq: $language } } }
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                content
                title
                uri
                date
                featuredImage {
                    node {
                        remoteFile {
                            ...Thumbnail
                        }
                    }
                }
            }
        }

        wp {
            homepage {
                slug
                title
                content
                language {
                    slug
                }
                seo {
                    metaDesc
                    metaKeywords
                    metaRobotsNoindex
                    metaRobotsNofollow
                    title
                    opengraphSiteName
                    opengraphDescription
                    opengraphImage {
                        sourceUrl
                    }
                }

                translations {
                    content
                    language {
                        slug
                    }
                    seo {
                        metaDesc
                        metaKeywords
                        metaRobotsNoindex
                        metaRobotsNofollow
                        title
                        opengraphSiteName
                        opengraphDescription
                        opengraphImage {
                            sourceUrl
                        }
                    }
                }
            }
        }

        allWpHomepageSlide(
            sort: { fields: date, order: DESC }
            filter: { language: { slug: { eq: $language } } }
        ) {
            nodes {
                title
                content
                date
                featuredImage {
                    node {
                        remoteFile {
                            ...Thumbnail
                        }
                    }
                }
            }
        }
    }
`;
