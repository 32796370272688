import React, {Component} from 'react';
import Carousel from 'react-elastic-carousel';
import NewsImg from "../../assets/images/news.png";

export default class HomepageSlider extends Component {
  /**
   * Map graphql query response and build an array
   *
   * @param nodes
   * @returns {*}
   */
  static buildSlide({nodes}) {
    return nodes.map((node) => {
      return {
        title: node.title,
        content: node.content,
        image: node.featuredImage
          ? node.featuredImage.node.remoteFile.childImageSharp.fluid.srcSet
          : NewsImg,
      };
    });
  }

  render() {
    return this.props.slides.nodes.length ? (
      <Carousel
        className={"hero-slider"}
        itemPadding={[0, 0, 0]}
        enableAutoPlay={true}
        autoPlaySpeed={4000}
        showArrows={false}>
        {HomepageSlider.buildSlide(this.props.slides).map((item, index) =>
          <div key={"slide_" + index} className={"slide"}>
            <div className={"slide__overlay"}/>
            <img className={"slide__img"} alt={item.title} srcSet={item.image}/>
            <div className={"container"}>
              <div className={"slide-container"}>
                <h1 className="slide__title" dangerouslySetInnerHTML={{__html: item.title}}/>
                <div dangerouslySetInnerHTML={{__html: item.content}}/>
              </div>
            </div>
          </div>
        )}
      </Carousel>
    ) : null
  }
}
