import React, {Component} from 'react';
import Arrow from '../../assets/images/arrow.svg';
import {Link} from 'gatsby';
import NewsList from '../NewsList';
import {Trans} from 'gatsby-plugin-react-i18next';

export default class HomeNews extends Component {
  render() {
    const {news} = this.props;

    return news.nodes.length ? (
      <div className={'container d-flex flex-column'}>
        <h2 className={'news__title'}>
          <Trans>news.title</Trans>
        </h2>

        <NewsList news={news}/>

        <Link to={'blog'} className={'btn btn--link btn--news'}>
          <span>
            <Trans>news.button.all</Trans>
          </span>
          <img className={'link-icon'} src={Arrow} alt={'arrow'}/>
        </Link>
      </div>
    ) : false;
  }
}
