import React, {Component} from 'react';
import SolutionsCarousel from '../SolutionsCarousel';
import SolutionImg from '../../assets/images/solution.png';

export default class Solutions extends Component {
  state = {
    title: 'Отраслевые охранные решения',
    description: 'Наработанный опыт позволяет нам сразу предложить для Вас наиболее оптимальные решения для вашего бизнеса!',
    items: [
      {
        title: 'Финансовому сектору',
        text: 'Многолетний опыт, отлаженная система работы, гарантия качества.',
        img: SolutionImg,
      },
      {
        title: 'Портам',
        text: 'Охрана портов, судов и грузов. Ультрасовременные системы охраны.',
        img: SolutionImg,
      },
      {
        title: 'Перевозчикам',
        text: 'Доверяйте сопровождение грузов сотрудникам компании «EVORS Group».',
        img: SolutionImg,
      },
      {
        title: 'Перевозчикам',
        text: 'Доверяйте сопровождение грузов сотрудникам компании «EVORS Group».',
        img: SolutionImg,
      },
    ],
  };

  render() {
    const {items} = this.state;
    const lgCards = items.length <= 3 ? ' items--lg' : '';
    return (
      <div className={'container'}>
        <h2 className={'title text-center mt-sm'}>{this.state.title}</h2>
        {this.state.description ? <p className={'text-center'}>{this.state.description}</p> : ''}
        <div className={'solutions' + lgCards}>
          <SolutionsCarousel items={items}/>
        </div>
      </div>
    );
  }
}
