import React, {Component} from 'react';
import Carousel from 'react-elastic-carousel';
import SolutionsItem from '../SolutionsItem';

export default class SolutionsCarousel extends Component {
  render() {
    const {items} = this.props;
    return (
      <Carousel
        className={'solutions-slider'}
        itemPadding={[30, 30, 30, 30, 30]}
        enableAutoPlay={true}
        showArrows={false}
        breakPoints={[
          {width: 450, itemsToShow: 1, itemsToScroll: 1},
          {width: 650, itemsToShow: 2, itemsToScroll: 2},
          {width: 1000, itemsToShow: 4, itemsToScroll: 4},
        ]}
        autoPlaySpeed={4000}
        pagination={false}>
        {items.map((item, index) => <SolutionsItem item={item} key={index} />)}
      </Carousel>
    );
  }
}
