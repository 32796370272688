import React from 'react';

export default (data) => {
  const {item} = data;

  return (
    <>
      <div className={'solution'}>
        <div className={'solution__content'}>
          <img className={'solution__img'} src={item.img} alt={'solution'}/>
          <h3>{item.title}</h3>
          {item.text ? <p>{item.text}</p> : ''}
        </div>
      </div>
    </>
  );
}
