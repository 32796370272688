import React from 'react';
import Solutions from "../Solutions";

export default function HomeContent({data}) {
  const {content} = data;

  return (
    <>
      <div className={'page-content'} dangerouslySetInnerHTML={{__html: content}}/>
      {/*<Solutions/>*/}
    </>
  );
}
